:root {

    // AsicJungleAnalytics Brand Colors
    --brand-green: #48C639;
    --brand-green-10: #a4e39c;
    --brand-green-50: #6dd161;
    --brand-green-100: var(--brand-green);
    --brand-green-200: #3a9e2e;
    --brand-green-300: #2b7722;
    --brand-green-400: #063831;

    --brand-blue: #3385ae;
    --brand-blue-10: #85b6ce;
    --brand-blue-50: #5c9dbe;
    --brand-blue-100: var(--brand-blue);
    --brand-blue-200: #245d7a;
    --brand-blue-300: #1a4357;
    --brand-blue-400: #0f2834;
}